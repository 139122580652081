<template>
  <div class="box">
    <div  class="header">
        <div>
           <div class="header-title">收货地址</div>
           <!-- <div class="header-line"></div>  -->
        </div>
        <span>*请在小程序端进行操作</span>
    </div>
    <el-table
    :data="tableData"
    style="width: 97%;margin: 0 auto;"
    class="table"
    >
    <el-table-column
      prop="receiver"
      label="收货人"
      sortable
      width="130">
    </el-table-column>
    <el-table-column
      prop="contactDetails"
      label="联系方式"
      sortable
      width="130">
    </el-table-column>
    <!-- <el-table-column
      prop="postcode"
      label="邮政编码"
       width="130"
      sortable>
    </el-table-column> -->
    <el-table-column
      prop="area"
      label="所在地区"
      sortable>
    </el-table-column>
    <el-table-column
      prop="address"
      label="详细地址"
      sortable>
    </el-table-column>
  </el-table>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'sheepHose',
  data(){
      return{
        tableData: []
      }
  },
  mounted(){
    this.getData()
  },
  methods:{
    getData() {
      this.qa.queryShippingAddressList({}).then(res => {
        this.tableData = res.data.list
      })
    },
    formatter(row, column) {
        return row.address;
      }
  }
}
</script>

<style scoped>
  .box {
    background: #fff;
    min-height: 700px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 21px 20px 0 15px;
  }
  .header-line {
    width: 20px;
    height: 4px;
    background: #84C754;
    margin: 5px auto;
  }
  .header-title {
    font-size: 18px;
    font-weight: 600;
  }
</style>